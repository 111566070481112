@import "./variables.scss";

.field {
  margin-bottom: 1em;

  .field {
    margin-bottom: 0;
  }
}

form {
  .button-row {
    button {
      margin-right: 1em;
    }
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: var(--color-field-placeholder);
    opacity: 1;
  }

  .simple-select {
    position: relative;
    width: 100%;
  }

  input,
  textarea,
  .select-input {
    background-color: $formFieldBgColor;
    border-radius: 6px;
    outline: none;
    padding: 1em 1em;
    width: 100%;
    font-family: "Inter";
    box-shadow: none;
    border: none;
    display: block;
    transition: 0.3s all;
    height: 44px;

    &:active,
    &:focus {
      outline: none;
    }

    &:focus {
      outline: none;
      background-color: #f5f5f5;
    }
  }

  .select-input {
    .text {
      font-family: "Inter";
      font-weight: 500;

      &.default {
        color: var(--color-field-placeholder);
        opacity: 1;
      }
    }
  }

  .ui.selection.dropdown > .text {
    color: black;
    font-weight: 500;
  }

  input {
    line-height: 1em;
    font-weight: 500;
  }

  textarea {
    padding-top: 0.7em;
    line-height: 1.5em;
    min-height: 10em;
    resize: vertical;
  }

  button {
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:disabled {
      transition: none;

      &:hover {
        background-color: var(--color-primary);
      }
    }
  }

  .field-row {
    display: grid;
    column-gap: 0.7em;

    .field-wrap {
      width: 100%;
    }
  }

  label {
    display: block;
    font-weight: 800;
    font-size: 14px;
    padding-bottom: 6px;
  }

  .field.error {
    input,
    textarea,
    .simple-select > .select-input {
      border: 1px solid #ec4a4ac0;
    }

    .downshift-select {
      border: 1px solid #ec4a4ac0;

      input {
        border: none;
      }
    }
  }
}

.App.dark {
  form {
    input,
    textarea {
      background-color: var(--color-field-bg);
      color: white;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: $darkTextColor;
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder {
      color: $darkTextColor;
    }

    input:-ms-input-placeholder,
    textarea::-moz-placeholder {
      color: $darkTextColor;
    }

    .simple-select > .select-input {
      background-color: var(--color-field-bg);
      color: white;

      .default.text {
        color: $darkTextColor;
      }
    }

    .select-menu {
      background-color: #3e6683;
    }

    .note {
      color: #718fa5;
    }
  }
}
