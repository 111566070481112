@import "./variables.scss";

h1,
h2,
h3,
h4,
h5,
.ui.input > input,
body,
button,
input,
select,
textarea {
  font-family: $fontFamily;
}

h1,
h2,
h3,
h4,
h5,
button {
  letter-spacing: -0.02em;
  font-weight: 800;
  font-size: 26px;
  line-height: 1em;
  color: #1b2732;
}

h1 {
  font-weight: 800;
}

h3 {
  font-size: 18px;
}

h4 {
  text-transform: uppercase;
  color: #6b6b6b;
  font-size: 15px;
  font-weight: 600;
}

button {
  box-shadow: none;
  border: none;
  border-radius: 6px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
  padding: 1em 2.5em;
  font-weight: 700;
  font-size: 15px;
  outline: none;
  display: block;
  transition: all 0.3s;

  &:active {
    outline: none;
    background-color: black;
  }

  &:hover {
    background-color: var(--color-primary-light);
  }

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &[disabled]:hover {
    transition: none;

    &:hover {
      background-color: var(--color-primary);
    }
  }
}

hr {
  border-bottom: none;
  border-top: 1px solid #e0e0e0;
  margin: 1em 0;
}

.Popover-tip {
  display: none;
}
