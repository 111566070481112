.sidebar {
  background-color: #1b2732;
  text-align: left;
  position: relative;
  grid-column-start: collapsed-sidebar;
  grid-column-end: content;

  &.collapsed {
    grid-column-end: expanded-sidebar;
    position: fixed;
    bottom: 0;
    top: 0;

    li {
      display: block;

      .subnav {
        padding-left: 0;
      }
    }
    li > a {
      padding: 0.9em 0;
      text-align: center;
      display: block;

      svg {
        display: block;
        margin: 0 auto;
        margin-bottom: 6px;
        transition: 0.2s transform ease-in-out;
      }

      &:hover {
        svg {
          transform: scale(1.2);
        }
      }
    }

    .sidebar-inner {
      padding: 0 10px;
    }
  }

  .sidebar-inner {
    margin: 60px auto 0 auto;
    padding: 0 30px;
  }

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: #c8c8c8;
    font-weight: 700;
  }

  ul,
  li {
    list-style: none;
    padding-left: 0;

    .subnav {
      padding-left: 1em;

      li > a {
        font-size: 14px;
      }
    }
  }

  li > a {
    color: white;
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s all;
    padding: 0.6em 0;
    display: flex;
    line-height: 1em;
    align-items: center;

    svg {
      margin-right: 6px;
    }

    &:hover {
      color: #aac0d6;
    }
  }

  ul > li > a.active {
    color: #8b96a1;
  }
}

.App.dark {
  .sidebar {
    ul > li > a.active {
      color: #c7ddec;
      text-decoration: underline;
    }
  }
}
