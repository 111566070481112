.order-details {
  border: 1px solid #abc4db;
  border-radius: 4px;
  padding: 1.2em 1.3em;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.18);
  color: #212121;

  table {
    width: 100%;
    font-size: 15px;

    tbody > tr > td:first-child {
      padding-right: 1em;
      text-align: left;
    }

    tbody > tr > td:last-child {
      font-weight: 500;
    }

    tbody > tr > td {
      $verticalPadding: 0.55em;
      line-height: 1em;
      padding-top: $verticalPadding;
      padding-bottom: $verticalPadding;
    }

    tbody > tr.total > td {
      font-size: 17px;
      padding-bottom: 0;
      &:last-child {
        font-weight: 800;
      }
    }

    tbody > tr > td:last-child {
      width: 50%;
      text-align: right;
    }
  }
}
