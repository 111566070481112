@import-normalize;

:root {
  --color-primary: #1b2732;
  --color-secondary: #37b99b;
  --color-purple: #5653e3;
  --color-link: #3D97EA;
  --color-primary-light: #2a3845;
  --color-primary-dark: #131e28;
  --color-steel-blue: #5b7288;
  --color-icon: #8b97a1;
  --base-font-size: 14px;
  --color-lt-grey: #fafafa;
  --color-mid-grey: #535353;
  --input-color: #2d2d2d;
  --font-family: Inter, sans-serif;
  --color-field-bg: #fafafa;
  --color-field-border-error: #D13939;
  --color-input-error-text: #D13939;
  --color-input-error-background: #f7ecec;
  --color-field-border: #c9cdda;
  --color-menu-item-highlight: #f0f1f7;
  --color-field-placeholder: #a8a8a8;
}

input {
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

pre, pre * {
  font-family: monospace;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.StripeElement {
  padding: 15px 1em;
  border-radius: 6px;
  background-color: var(--color-field-bg);
  border: 1px solid var(--color-field-border);
}

.StripeElement.error {
  border: 1px solid var(--color-field-border-error);
}