@import "../../css/variables.scss";
@import "../../css/mixins.scss";

.collect-home {
  color: #323232;
  padding-bottom: 2em;
  .vv-field {
    align-items: center;
  }

  .field {
    width: 100%;
  }

  p {
    line-height: 1.6em;
    font-size: 14px;
  }

  .collect-form {
    max-width: 420px;
    margin: 0 auto;
    margin-top: 1em;

    text-align: left;

    .note {
      padding-left: 0;
    }

    button {
      font-weight: 600;
      padding-top: 1.2em;
      padding-bottom: 1.2em;
    }

    .purchase-note {
      margin-top: 1em;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .state-zip-wrap {
    .simple-select {
      margin-right: 0.4em;
    }
    & > .vv-field > .text-field {
      margin-bottom: 0;
    }
  }
}

@include mobile-only {
  .collect-home {
    padding-left: 1em;
    padding-right: 1em;
  }
}
