@import "../../../css/variables.scss";

.date-picker-input {
  .date-input {
    .text.default {
      font-size: 14px;
      color: var(--color-field-placeholder);
      line-height: 24px;
    }
  }
}

.ui.selection.dropdown.date-input {
  min-height: 44px;
}

.App.dark {
  .ui.selection.date-input .text.default {
    color: $darkTextColor;
  }
}
