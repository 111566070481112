@import "./css/base.scss";
@import "./css/dark.scss";
@import "./css/forms.scss";
@import "./css/mixins.scss";

body,
html,
#root {
  height: 100%;
  letter-spacing: -0.02em;
  background-color: #f3f3f3;
}

.App {
  text-align: left;
  height: 100%;

  @include mobile-only {
    padding: 0 1em;
  }
}
