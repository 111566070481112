/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap'); */

:root {
  --color-primary: #1b2732;
  --color-primary-light: #2a3845;
  --color-primary-dark: #131e28;
  --color-steel-blue: #5b7288;
  --color-icon: #8b97a1;
  --color-lt-grey: #FAFAFA;
  --color-mid-grey: #a8a8a8;
  --font-family: Inter, sans-serif;
  --base-font-size: 16px;
}

.replii-widget-root {
  font-family: var(--font-family);
  letter-spacing: -.02em;
}

.replii-widget-root, .replii-widget-root * {
  box-sizing: border-box;
}