@import "../../css/variables.scss";

.checkbox-field {
  cursor: pointer;
  margin-bottom: 1em;

  &.error {
    .replii-checkbox {
      border: 1px solid #ec4a4ac0;
    }
  }

  &[disabled] {
    opacity: 0.5;
  }

  &.left {
    label > span {
      margin-right: 0.8em;
    }
  }

  &.right {
    label > span {
      margin-left: 0.8em;
    }
  }

  label {
    display: flex;
    align-items: center;
    padding-bottom: 0;
  }

  .note {
    margin-top: 0.2em;
    padding-left: 2.5em;
    max-width: 100%;
  }

  .replii-checkbox {
    $size: 20px;
    width: $size;
    min-width: $size;
    height: $size;
    background-color: #e5e8ec;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all;

    &.checked {
      background-color: var(--color-steel-blue);

      svg {
        color: white;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.App.dark {
  .checkbox-field {
    label {
      color: $darkTextColor;
    }
  }
}
