.downshift-select {
  .select-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    input {
      width: 100%;
      display: block;
      margin-bottom: 0;
      cursor: pointer;
    }

    svg {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }

  .menu {
    li {
      // background-color: #fff;
      list-style: none;
      padding: 1em;
    }
  }
}
