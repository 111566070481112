.rm-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 11;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rm-content {
  background-color: white;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  min-width: 600px;
  max-width: 700px;
  max-height: 80%;
  max-height: calc(100vh - 2em);
  overflow-y: auto;
  position: fixed;
  padding: 1.5em;
  border-radius: 10px;
}

.modal-body {
  overflow-y: auto;
}

.rm-content:focus {
  outline: none;
}