table.data-table {
  width: 100%;
  color: var(--color-primary);

  thead {
    tr > th {
      border-bottom: 1px solid #b5c1cc;
      padding: 1em;
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    tr > td {
      color: #1e1e1e;
      padding: 0.8em 1em;
    }
  }
}
