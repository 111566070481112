@import "../../css/variables";

a {
  font-weight: 700;
  color: var(--color-link);
  transition: 0.3s all;

  &:hover {
    color: var(--color-link);
    //filter: brightness(110%) hue-rotate(-2deg);
  }
}

.dashboard-home {
  height: 100%;
  display: grid;
  grid-template-columns: [collapsed-sidebar] 116px [expanded-sidebar] 180px [content] minmax(auto, 1200px) [end];

  &.collapsed {
    .main {
      grid-column-start: expanded-sidebar;
      grid-column-end: end;
    }
  }

  .main {
    grid-column-start: content;
    background-color: #f4f5f7;
    padding-top: 60px;
    padding-left: 2em;
    padding-right: 2em;
    text-align: left;
  }

  form .field-row {
    grid-template-columns: 60% auto;
  }

  .main > h1:first-child {
    // margin-top: 0;
  }
}

.App.dark {
  color: $darkTextColor;

  .dashboard-home {
    .sidebar {
      background-color: #2d516b;

      li > a {
        color: $darkTextColor;
      }
    }

    .main {
      background-color: $darkMainBgColor;
    }
  }
}
