.vv-date-picker {
  font-size: 14px;

  .change-month-buttons {
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    padding: 0.7em 1em;

    .month-year {
      flex-grow: 2;
      font-weight: 600;
      text-align: center;
    }

    button.change-month {
      background: transparent;
      border: none;
      cursor: pointer;
      margin: 0;
      padding: 0 0.3em;

      &:active,
      &:focus {
        outline: none;
      }

      svg {
        color: #ccc;
        width: 15px;

        &:active,
        &:focus {
          outline: none;
        }
      }
    }
  }

  table.calendar {
    text-align: center;
    width: 100%;

    thead {
      th {
        border-bottom: 1px solid rgba(0, 61, 92, 0.1);
        font-weight: 600;
        padding: 0.6em 0.5em;
        text-align: center;
      }
    }

    tbody > tr {
      td {
        border-bottom: 1px solid rgba(0, 61, 92, 0.1);
        border-right: 1px solid rgba(0, 61, 92, 0.1);
        cursor: pointer;
        font-weight: 500;
        letter-spacing: 0.4px;
        line-height: 1em;
        padding: 0.8em;
        text-align: center;
        transition: all 0.3s;

        @media only screen and (max-width: 529px) {
          font-size: 16px;
          padding: 0.6em;
        }
      }

      td.day.selected {
        background-color: var(--color-steel-blue);
        color: #ffffff;
      }

      td.day.today {
        font-weight: bold;
      }

      td:hover {
        background-color: #f6fafe;
      }

      td.day[disabled] {
        background-color: #f9f9f9;
        color: #bababa;
        cursor: not-allowed;
        opacity: 0.6;
      }

      td:last-child {
        border-right: 0;
      }
    }

    tbody > tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}

// popup modal styles
.date-picker-popup,
.ui.bottom.left.ui.popup.date-picker-popup {
  max-width: 340px;
  padding: 0 !important;

  @media only screen and (max-width: 529px) {
    width: 89% !important;
  }
}

.App.dark {
}
