.campaign-message-form {
  max-width: 740px;
  padding-bottom: 60px;

  textarea {
    min-height: 200px;
  }

  .button-row {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 10;

    .button-inner {
      margin: 0 auto;
      width: 800px;
      max-width: 800px;
    }
  }

  .date-time-input {
    .field-wrap {
      display: flex;
      width: 100%;

      .time-field {
        width: 100%;

        .downshift-select {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
